.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-size: 0.75rem;
  padding: 0 1.5rem;
  background-color: #e2e8f0;
  color: #1e293b;
  &--sm {
    height: 1.5rem;
    font-size: 0.75rem;
  }
  &--lg {
    height: 2.5rem;
    font-size: 0.85rem;
  }
  &--xl {
    height: 3rem;
    font-size: 0.9rem;
  }
  &--new {
    background-color: #ec4899;
    color: white;
  }
  &--open {
    background-color: #0ea5e9;
    color: white;
  }
  &--hold {
    background-color: #f97316;
    color: #fff7ed;
  }
  &--solved {
    background-color: #14b8a6;
    color: #f0fdfa;
  }
  &--closed {
    background-color: #64748b;
    color: #cbd5e1;
  }
  &--expertise,
  &--delegate {
    background-color: #6366f1;
    color: white;
  }
  &--accepted,
  &--received,
  &-received {
    background-color: #22c55e;
    color: #f0fdf4;
  }
  &--rejected,
  &--not-received,
  &-not-received {
    background-color: #f43f5e;
    color: #fff1f2;
  }
  &--red {
    background-color: #e0f2fe;
    color: #0284c7;
  }
  &--red-answered,
  &--red_answered, {
    background-color: #e0f2fe;
    color: #0284c7;
  }
  &--pending,
  &-pending {
    background-color: #cbd5e1;
    color: #3b82f6;
  }
}