.badge {
    &.badge-flat {
        background-color: hsl(240, 50%, 91%);
        border-radius: 0.45rem !important;
        padding: 0.75rem 1.25rem;
        color: hsl(240, 50%, 50%);
        &.badge-pending {
            background-color: hsl(40, 100%, 62%);
            color: hsl(40, 100%, 12%);
        }
        &.badge-accepted, 
        &.badge-success, 
        &.badge-green {
            background-color: hsl(141, 100%, 34%);
            color: white;
        }
        &.badge-rejected,
        &.nbadge-danger,
        &.badge-red {
            background-color: hsl(356, 100%, 40%);
            color: white;
        }
    }
}