.accordion {
  &.accordion-flat {
    border: 0 !important;
    border-radius: 0 !important;
    .accordion-item {
      border: 0;
      .accordion-header {
        .accordion-button {
          box-shadow: none;
          border-radius: 0;
        }
        &__lvl0 {
          .accordion-button {
            padding: 1.75rem 2rem;
            font-size: 1.25rem;
            font-weight: 600;
            &:not(.collapsed) {
              background-color: hsl(212deg, 100%, 96%) !important;
              color: hsl(212deg, 100%, 30%);
            }
          }
        }
        &__lvl1 {
          .accordion-button {
            padding: 1.25rem 2rem;
            font-size: 1.05rem;
            font-weight: 600;
            &:not(.collapsed) {
              background-color: hsl(212deg, 100%, 98%) !important;
              color: hsl(212deg, 100%, 40%);
            }
          }
        }
      }
      .accordion-collapse {
        border: 0;
        .section {
          padding: 1rem 1.5rem;
          &:last-child {
            border-bottom: 6px solid hsl(212deg, 20%, 96%);
          }
        }
        .accordion-body {
          &__lvl0 {
            padding: 0;
            .section {
              padding: 1rem 1.5rem;
              border-bottom: 0;
              &:last-child {
                border-bottom: 8px solid hsl(212deg, 20%, 80%);
              }
            }
          }
          &__lvl1 {
            padding: 0;
            .section {
              padding: 1rem 1.5rem;
              border-bottom: 0;
              &:last-child {
                border-bottom: 2px solid hsl(212deg, 20%, 80%);
              }
            }
          }
        }
      }
    }
  }
}