div#sidebar {
  background-color: white;
  background-image: url('../../../public/img/arc_yellow.jpg');
  background-repeat: no-repeat;
  background-size: 120px;
  background-position: bottom left;
  @media (min-width: 768px) {
    background-size: 120px;
  }
  @media (min-width: 1024px) {
    background-size: 120px;
  }
  @media (min-width: 1280px) {
    background-size: 120px;
  }
  &.c-sidebar {
    position: fixed;
    display: none;
    top: 0;
    bottom: 0;
    z-index: 2000;
    background-color: hsl(220deg, 20%, 100%);
    width: 15rem;
    @media (min-width: 992px) {
      display: flex;
      width: 16rem;
      flex-direction: column;
      justify-content: space-between;
    }
    flex-direction: column;
    .c-brand-logo {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .c-sidebar-nav {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      margin-bottom: 0!important;
      .nav-item {
        width: inherit;
        list-style: none;
        border-right: 0px solid transparent;
        border-radius: 0.4rem;
        margin-bottom: 0.2rem;
        .nav-link {
          border-radius: 0.4rem;
          display: flex;
          //flex-direction: column;
          align-items: center;
          padding: 0.4rem 0.5rem;
          color: #003066;
          .link-icon-wrapper {
            position: relative;
            display: block;
            width: 20px;
            height: 20px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            margin-bottom: 0.15rem;
            overflow: hidden !important;
            margin-right: 1rem;
            .link-icon {
              position: relative;
              display: block;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              transform: translate3d(0, 0, 0);
              transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
              &::before {
                position: absolute;
                width: 100%;
                height: 100%;
                content: "";
                transform: translate3d(0, -100%, 0);
                transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
              }
              &.link-icon-home {
                background-image: url("/img/sidebar/home-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/home-primary.svg");
                }
              }
              &.link-icon-home-plain {
                background-image: url("/img/sidebar/home-plain-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/home-plain-primary.svg");
                }
              }
              &.link-icon-atom {
                background-image: url("/img/sidebar/atom-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/atom-primary.svg");
                }
              }
              &.link-icon-atom2 {
                background-image: url("/img/sidebar/atom2-darkblue.svg");
              }
              &.link-icon-bike {
                background-image: url("/img/sidebar/bike-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/bike-primary.svg");
                }
              }
              &.link-icon-book {
                background-image: url("/img/sidebar/book-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/book-primary.svg");
                }
              }
              &.link-icon-document {
                background-image: url("/img/sidebar/document-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/document-primary.svg");
                }
              }
              &.link-icon-education {
                background-image: url("/img/sidebar/education-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/education-primary.svg");
                }
              }
              &.link-icon-newspaper {
                background-image: url("/img/sidebar/newspaper-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/newspaper-primary.svg");
                }
              }
              &.link-icon-paperplane {
                background-image: url("/img/sidebar/paperplane-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/paperplane-primary.svg");
                }
              }
              &.link-icon-laposte {
                background-image: url("/img/sidebar/laposte-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/laposte-primary.svg");
                }
              }
              &.link-icon-power {
                background-image: url("/img/sidebar/power-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/power-primary.svg");
                }
              }
              &.link-icon-repair {
                background-image: url("/img/sidebar/repair-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/repair-primary.svg");
                }
              }
              &.link-icon-shop {
                background-image: url("/img/sidebar/shop-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/shop-primary.svg");
                }
              }
              &.link-icon-talk {
                background-image: url("/img/sidebar/talk-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/talk-primary.svg");
                }
              }
              &.link-icon-user {
                background-image: url("/img/sidebar/user-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/user-primary.svg");
                }
              }
              &.link-icon-video {
                background-image: url("/img/sidebar/video-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/video-primary.svg");
                }
              }
              &.link-icon-wallet {
                background-image: url("/img/sidebar/wallet-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/wallet-primary.svg");
                }
              }
              &.link-icon-matrix {
                background-image: url("/img/sidebar/matrix-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/matrix-primary.svg");
                }
              }
              &.link-icon-grid {
                background-image: url("/img/sidebar/grid-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/grid-primary.svg");
                }
              }
              &.link-icon-statamic {
                background-image: url("/img/sidebar/statamic-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/statamic-primary.svg");
                }
                &.link-icon-statamic-pink {
                  background-image: url("/img/sidebar/statamic-hotpink.svg");
                  &:before {
                    background-image: url("/img/sidebar/statamic-darkpink.svg");
                  }
                }
              }
              &.link-icon-zendesk-support {
                background-image: url("/img/sidebar/zendesk-support-blue.svg");
                &:before {
                  background-image: url("/img/sidebar/zendesk-support-color.svg");
                }
              }
            }
          }
          &:hover {
            background-color:hsla(220deg, 80%, 50%, 0.06);
            .link-icon-wrapper {
              .link-icon {
                display: block;
                width: 20px;
                height: 20px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                margin-bottom: 0.15rem;
                transform: translate3d(0, 100%, 0);
              }
            }
          }
          @media screen and (max-height: 768px) {
            padding: 0.15rem 0.5rem;
          }
        }
        &.nav-item-active {
          border-color: hsl(212deg, 100%, 50%);
          background-color: hsla(220deg, 100%, 50%, 0.12);
          font-weight: 700;
          color: hsl(212deg, 100%, 50%);
          .nav-link {
            color: inherit;
            .link-icon-wrapper {
              .link-icon {
                transform: translate3d(0, 100%, 0);
              }
            }
          }
        }
      }
      @media screen and (max-height: 786px) {
        padding: 0 1rem !important;
      }
    }
    .c-sidebar-subnav {
      display: flex;
      flex-direction: column;
      margin: 0;
      .nav-item {
        .nav-link {
          padding: 0.15rem 1.5rem;
          color: #003066;
          display: flex;
          align-items: center;
        }
      }
    }
    .c-sidebar-bottom-nav {
      display: flex;
      flex-direction: column;
      margin: 0;
      .nav-item {
        .nav-link {
          padding: 0.75rem 1.5rem;
          color: white;
          display: flex;
          align-items: center;
          border-radius: 0.4rem;
          &.logout {
            padding: 0.5rem 0.75rem;
            background: #f43f5e;
            color: white;
            border: 1px solid #e11d48;
            transition: all .1s;
            &:hover {
              background: #fb7185;
              border: 1px solid #f43f5e;
            }
            &:active, &:focus {
              // box-shadow: none;
              // box-shadow: 0px 2px 4px rgba(0,0,0,0.2) inset;
              //color: red;
            }
            @media screen and (max-width: 768px) {
              background-color: hsl(348deg, 78%, 45%) !important;
              color: white !important;
            }
          }
          .link-icon-wrapper {
            position: relative;
            display: block;
            width: 20px;
            height: 20px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            margin-bottom: 0.15rem;
            overflow: hidden !important;
            margin-right: 1rem;
            .link-icon {
              position: relative;
              display: block;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              transform: translate3d(0, 0, 0);
              transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
              &::before {
                position: absolute;
                width: 100%;
                height: 100%;
                content: "";
                transform: translate3d(0, -100%, 0);
                transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
              }
              &.link-icon-power {
                background-image: url("/img/sidebar/power-white.svg");
                &:before {
                  background-image: url("/img/sidebar/power-white.svg");
                }
              }
            }
          }
        }
      }
    }
  }
  #siedebar-secondary {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
  }
}
