a:link,
a:visited,
a:active {
  color: hsl(220deg, 80%, 50%);
  text-decoration: none;
  h4 {
    color: black !important;
  }
}
a:hover {
  color: hsl(220deg, 80%, 30%);
}
