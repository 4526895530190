.labour-item {
  background-color: white;
  padding: 1rem 1.5rem;
  display: grid;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 0.5rem;
  .inner {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    &--info {

    }
    &--badges {
      width: 240px;
      .badge-title {
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }
    .row-attribute {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0rem;
      margin-bottom: 1rem;
      @media screen and (min-width: 1200px) {
        grid-template-columns: 1fr 2fr;
        margin-bottom: 0rem;
        gap: 1rem;
      }
      .attr-k,
      .attr-key,
      .attr-label {
        font-weight: 600;
      }
    }
  }
  .annotation {
    background-color: hsl(220, 10%, 96%);
    font-size: 0.85rem;
    padding: 0.5rem 1rem;
    border-radius: 0.15rem;
  }
}