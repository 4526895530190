.messages-history {
  box-shadow: 0px 1px 1px rgba(0,0,0,0.05), 0px 2px 6px rgba(0,0,0,0.05);
  border-radius: 0.5rem;
  .message-item {
    .hash {
      color: rgb(172, 176, 202);
    }
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    background-color: white;
    //margin-bottom: 0.25rem;
    //border-radius: 0.25rem;
    padding: 1.25rem 1.5rem;
    border-bottom: 1Px solid rgb(231, 235, 242);
    @media (min-width: 1400px) {
      grid-template-columns: auto 1fr auto;
    }
    .info {
      width: 220px;
      @media (min-width: 1600px) {
        width: 240px;
      }
      .id {
        display: inline-block;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      .theme {
        display: inline-block;
        font-size: 0.8rem;
        padding: 0.25rem 0.75rem;
        border: 1px solid rgb(158, 169, 220);
        color: rgb(29, 38, 82);
        background-color: rgb(245, 246, 251);
        border-radius: 2rem;
        margin-bottom: 0.5rem;
      }
      .subject {
  
      }
    }
    .comment {
  
    }
    .meta {
      width: 220px;
    }
    &:first-child {
      border-radius: 0.5rem 0.5rem 0 0;
    }
    &:last-child {
      border-radius: 0 0 0.5rem 0.5rem;
      border-bottom-width: 0;
    }
  }
}