div.step {
  //border: 1px solid #dcdee6;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  background-color: #dcdee6;
  border-radius: 0.5rem;
  //box-shadow: 0px 2px 3px rgba(47, 42, 53, 0.05);
  transition: all 0.45s;
  font-size: 0.75rem;
  padding: 1rem !important;
  box-shadow: none !important;
  &.step-done {
    background: hsl(212deg, 100%, 40%);
    border: 0px solid #1288d6;
    small {
      border-color: #ffffff;
    }
  }
  &.step-current {
    background: hsl(212deg, 100%, 80%);
    color: #052c58;
    border: 0px solid #d7e6f7;
    small {
      border-color: #0b69d4;
    }
  }
  &.step-secondary {
    background: #ffffff;
    color: #434e53;
    // border-top: 1px solid #e2e9ee;
    // border-bottom: 1px solid #9da6aa;
  }
  small {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border: 1px solid #bfc4c7;
    padding: 0;
    margin-bottom: 0.25rem;
    border-radius: 1rem;
  }
}
