#last-tickets {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0rem;
  row-gap: 0.5rem;
  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .item-ticket {
    display: grid;
    border-radius: 0.25rem;
    line-height: 1.25rem;
    font-size: 0.9rem;
    grid-template-columns: 1fr;
    gap:1rem;
    background-color: white;
    padding: 1rem 1.5rem;
    &__id {
      .ticket-id {
        font-weight: 600;
        font-size: 1.25rem;
        width: 200px;
      }
      @media screen and (min-width: 1200px) {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid black;
      }
    }
    &__info {
      display: grid;
      gap: 1rem;
      grid-template-columns: auto 1fr;
      .product-image {
        width: 90px;
      }
      .product-info {

      }
    }
    &__statuses {
      justify-self: flex-end;
      width: 200px;
      border: 1px solid orange;
    }
  }
}

.ticket-card {
  background-color: white;
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  .ticket-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    @media (min-width: 1200px) {
      grid-template-columns: auto 1fr auto;
    }
    .product-image {
      width: 240px;
      @media (min-width: 1200px) {
        width: 140px;
      }
      @media (min-width: 1600px) {
        width: 240px;
      }
    }
    .ticket-info {
      display: block;
      @media (min-width: 1200px) {
        border-left: 1px solid rgb(223, 223, 223);
        padding-left: 1rem;
      }
      .product-info {
        .row-attribute {
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 1rem;
          .attr-k {
            font-weight: 600;
          }
          .attr-v {

          }
        }
      }
    }
    .ticket-badges,
    .expertise-badges {
      display: flex;
      gap: 1rem;
      @media (min-width: 1200px) {
        width: 200px;
        flex-direction: column;
      }
      .badge-title {
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }
  } 
  .ticket-actions {
    @media screen and (min-width: 1200px) {
      display: flex;
      gap: 1rem;
    }
  }
}