.overhaul {
  display: grid;
  grid-template-columns: 1fr 1fr auto auto auto;
  gap: 1rem;
  background-color: white;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  &__product {

  }
  &__comment {

  }
  &__attachements {
    width: 220px;
  }
  &__dates {
    width: 160px;
  }
  &__status {
    width: 160px;
  }
}