@import "variables";
@import "../global";
@import "~bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800;&display=swap');
@import "fs-menu";
@import "p";
@import "a";
@import "h";
@import "alert";
@import "card";
@import "list";
@import "button";
@import "sidebar";
@import "header";
@import "main";
@import "footer";
@import "logged-in-as";
@import "form";
@import "badge";
@import "picto";
@import "modal";
@import "table";
@import "colors";

@import "info-bar";
@import "support/step";
@import "support/dropzone";
@import "accordion";
@import "agent/customer";
@import "post";

@import "../flat/alert";
@import "../flat/button";
@import "../flat/badge";
@import "pagination";
@import "../cei/badge";

@import "dashboard/support";
@import "laposte/overhaul";
@import "support/contact/message";
@import "support/expertise";
@import "support/transport-damage";
@import "support/labour";

html,
body {
  font-family: Poppins, -apple-system, system-ui, sans-serif;
  //background: linear-gradient(0deg, var(--tw-slate-200), var(--tw-blue-50));
  background-color: #f1f5f9;
  font-weight: 400;
  min-height: 100vh;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

div#app {
  &.sidebar-nav {
    margin-left: 0;
    @media (min-width: 992px) {
      margin-left: 16rem;
    }
    @media (min-width: 1200px) {
      margin-left: 16rem;
    }
  }
}

main {
  font-size: 16px;
}