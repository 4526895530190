.cei-badge {
  display: inline-block;
  font-size: 0.7rem;
  background: rgb(232, 232, 237);
  padding: .1em .45rem;
  font-weight: 600;
  border-radius: 0.15rem;
  line-height: 1rem;
  &.badge-rounded {
    display: inline-flex;
    background-color: hsl(220deg, 30%, 98%);
    border: 1px solid hsl(220deg, 30%, 70%);
    color: hsl(220deg, 80%, 20%);
    padding: 0.25rem 0.75rem;
    border-radius: 0.05rem;
    border-radius: 1rem;
    font-size: 0.75rem;
    font-weight: 600;
    margin: 1px 0;
    text-transform: capitalize;
  }
  &.badge-support, 
  &.badge-status {
    display: inline-flex;
    background-color: hsl(220deg, 30%, 96%);
    color: hsl(220deg, 80%, 20%);
    padding: 0.25rem 0.75rem;
    border-radius: 0.05rem;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 1px 0;
    &.badge-accepted {
      border: 1px solid  hsl(148, 80%, 34%);
      background-color: hsl(148, 80%, 44%);
      color: hsl(148, 80%, 98%);
    }
    &.badge-rejected {
      border: 1px solid  hsl(340, 80%, 34%);
      background-color: hsl(340, 80%, 44%);
      color: hsl(340, 80%, 98%);
    }
    &.badge-delegate {
        border: 1px solid  hsl(271, 80%, 34%);
        background-color: hsl(271, 80%, 44%);
        color: hsl(148, 80%, 98%);
    }
    &.badge-new {
      border: 1px solid hsl(336, 100%, 51%);
      background-color: hsl(336, 50%, 96%);
      color: hsl(336, 100%, 31%);
    }
    &.badge-open {
        border: 1px solid hsl(206, 100%, 51%);
        background-color: hsl(206, 100%, 96%);
        color: hsl(206, 100%, 26%);
    }
    &.badge-hold {
        border: 1px solid hsl(33, 80%, 50%);
        background-color: hsl(33, 50%, 96%);
        color: hsl(33, 50%, 26%);
    }
    &.badge-solved {
        border: 1px solid hsl(191, 63%, 45%);
        background-color: hsl(191, 50%, 96%);
        color: hsl(191, 63%, 25%);
    }
    &.badge-pending {
      border: 1px solid hsl(29, 80%, 59%);
      background-color: hsl(29, 50%, 96%);
      color: hsl(29, 80%, 29%);
    }
    &.badge-package_received {
      border: 1px solid hsl(203, 89%, 49%);
      background-color: hsl(203, 89%, 96%);
      color: hsl(203, 89%, 26%);
    }
    &.badge-review {
      border: 1px solid  hsl(271, 60%, 43%);
      background-color: hsl(271, 60%, 53%);
      color: white;
    }
    &.badge-closed {
      border: 1px solid hsl(193, 9%, 50%);
      background-color: hsl(193, 9%, 96%);
      color: hsl(193, 9%, 31%);
    }
  }
  &.badge-assortment {
    display: inline-flex;
    background-color: rgb(241, 245, 252);
    border: 1px solid hsl(220deg, 80%, 50%);
    color: hsl(220deg, 80%, 20%);
    padding: 0.25rem 0.75rem;
    border-radius: 0.05rem;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 1px 0;
  }
  &.badge-online {
    display: inline-flex;
    background-color: rgb(241, 245, 252);
    border: 1px solid rgb(25, 169, 230);
    color: rgb(4, 55, 98);
    padding: 0.25rem 0.75rem;
    border-radius: 0.05rem;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 1px 0;
  }
  &.badge-physical {
    display: inline-flex;
    background-color: rgb(252, 253, 255);
    border: 1px solid rgb(148, 25, 230);
    color: rgb(95, 9, 121);
    padding: 0.25rem 0.75rem;
    border-radius: 0.05rem;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 1px 0;
  }
}