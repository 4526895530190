.table {
    &.table-product-info {
        font-size: 0.85rem;
        th {
            padding: 0.25rem 0.5rem;
        }
        td {
            padding: 0.25rem 0.5rem;
        }
    }
}