:root {
  --tw-slate-50: #f8fafc;
  --tw-slate-100: #f1f5f9;
  --tw-slate-200: #e2e8f0;
  --tw-slate-300: #cbd5e1;
  --tw-slate-400: #94a3b8;
  --tw-slate-500: #64748b;
  --tw-slate-600: #475569;
  --tw-slate-700: #334155;
  --tw-slate-800: #1e293b;
  --tw-slate-900: #0f172a;
  --tw-slate-950: #020617;

  --tw-gray-50: #f9fafb;
  --tw-gray-100: #f3f4f6;
  --tw-gray-200: #e5e7eb;
  --tw-gray-300: #d1d5db;
  --tw-gray-400: #9ca3af;
  --tw-gray-500: #6b7280;
  --tw-gray-600: #4b5563;
  --tw-gray-700: #374151;
  --tw-gray-800: #1f2937;
  --tw-gray-900: #111827;
  --tw-gray-950: #030712;

  --tw-gray-50: #f9fafb;
  --tw-gray-100: #f3f4f6;
  --tw-gray-200: #e5e7eb;
  --tw-gray-300: #d1d5db;
  --tw-gray-400: #9ca3af;
  --tw-gray-500: #6b7280;
  --tw-gray-600: #4b5563;
  --tw-gray-700: #374151;
  --tw-gray-800: #1f2937;
  --tw-gray-900: #111827;
  --tw-gray-950: #030712;

  --tw-zinc-50: #fafafa;
  --tw-zinc-100: #f4f4f5;
  --tw-zinc-200: #e4e4e7;
  --tw-zinc-300: #d4d4d8;
  --tw-zinc-400: #a1a1aa;
  --tw-zinc-500: #71717a;
  --tw-zinc-600: #52525b;
  --tw-zinc-700: #3f3f46;
  --tw-zinc-800: #27272a;
  --tw-zinc-900: #18181b;
  --tw-zinc-950: #09090b;

  --tw-neutral-50: #fafafa;
  --tw-neutral-100: #f5f5f5;
  --tw-neutral-200: #e5e5e5;
  --tw-neutral-300: #d4d4d4;
  --tw-neutral-400: #a3a3a3;
  --tw-neutral-500: #737373;
  --tw-neutral-600: #525252;
  --tw-neutral-700: #404040;
  --tw-neutral-800: #262626;
  --tw-neutral-900: #171717;
  --tw-neutral-950: #0a0a0a;

  --tw-stone-50: #fafaf9;
  --tw-stone-100: #f5f5f4;
  --tw-stone-200: #e7e5e4;
  --tw-stone-300: #d6d3d1;
  --tw-stone-400: #a8a29e;
  --tw-stone-500: #78716c;
  --tw-stone-600: #57534e;
  --tw-stone-700: #44403c;
  --tw-stone-800: #292524;
  --tw-stone-900: #1c1917;
  --tw-stone-950: #0c0a09;

  --tw-red-50: #fef2f2;
  --tw-red-100: #fee2e2;
  --tw-red-200: #fecaca;
  --tw-red-300: #fca5a5;
  --tw-red-400: #f87171;
  --tw-red-500: #ef4444;
  --tw-red-600: #dc2626;
  --tw-red-700: #b91c1c;
  --tw-red-800: #991b1b;
  --tw-red-900: #7f1d1d;
  --tw-red-950: #450a0a;

  --tw-orange-50: #fff7ed;
  --tw-orange-100: #ffedd5;
  --tw-orange-200: #fed7aa;
  --tw-orange-300: #fdba74;
  --tw-orange-400: #fb923c;
  --tw-orange-500: #f97316;
  --tw-orange-600: #ea580c;
  --tw-orange-700: #c2410c;
  --tw-orange-800: #9a3412;
  --tw-orange-900: #7c2d12;
  --tw-orange-950: #431407;

  --tw-amber-50: #fffbeb;
  --tw-amber-100: #fef3c7;
  --tw-amber-200: #fde68a;
  --tw-amber-300: #fcd34d;
  --tw-amber-400: #fbbf24;
  --tw-amber-500: #f59e0b;
  --tw-amber-600: #d97706;
  --tw-amber-700: #b45309;
  --tw-amber-800: #92400e;
  --tw-amber-900: #78350f;
  --tw-amber-950: #451a03;

  --tw-yellow-50: #fefce8;
  --tw-yellow-100: #fef9c3;
  --tw-yellow-200: #fef08a;
  --tw-yellow-300: #fde047;
  --tw-yellow-400: #facc15;
  --tw-yellow-500: #eab308;
  --tw-yellow-600: #ca8a04;
  --tw-yellow-700: #a16207;
  --tw-yellow-800: #854d0e;
  --tw-yellow-900: #713f12;
  --tw-yellow-950: #422006;

  --tw-lime-50: #f7fee7;
  --tw-lime-100: #ecfccb;
  --tw-lime-200: #d9f99d;
  --tw-lime-300: #bef264;
  --tw-lime-400: #a3e635;
  --tw-lime-500: #84cc16;
  --tw-lime-600: #65a30d;
  --tw-lime-700: #4d7c0f;
  --tw-lime-800: #3f6212;
  --tw-lime-900: #365314;
  --tw-lime-950: #1a2e05;

  --tw-green-50: #f0fdf4;
  --tw-green-100: #dcfce7;
  --tw-green-200: #bbf7d0;
  --tw-green-300: #86efac;
  --tw-green-400: #4ade80;
  --tw-green-500: #22c55e;
  --tw-green-600: #16a34a;
  --tw-green-700: #15803d;
  --tw-green-800: #166534;
  --tw-green-900: #14532d;
  --tw-green-950: #052e16;

  --tw-emerald-50: #ecfdf5;
  --tw-emerald-100: #d1fae5;
  --tw-emerald-200: #a7f3d0;
  --tw-emerald-300: #6ee7b7;
  --tw-emerald-400: #34d399;
  --tw-emerald-500: #10b981;
  --tw-emerald-600: #059669;
  --tw-emerald-700: #047857;
  --tw-emerald-800: #065f46;
  --tw-emerald-900: #064e3b;
  --tw-emerald-950: #022c22;

  --tw-teal-50: #f0fdfa;
  --tw-teal-100: #ccfbf1;
  --tw-teal-200: #99f6e4;
  --tw-teal-300: #5eead4;
  --tw-teal-400: #2dd4bf;
  --tw-teal-500: #14b8a6;
  --tw-teal-600: #0d9488;
  --tw-teal-700: #0f766e;
  --tw-teal-800: #115e59;
  --tw-teal-900: #134e4a;
  --tw-teal-950: #042f2e;

  --tw-cyan-50: #f0f9ff;
  --tw-cyan-100: #e0f2fe;
  --tw-cyan-200: #bae6fd;
  --tw-cyan-300: #7dd3fc;
  --tw-cyan-400: #38bdf8;
  --tw-cyan-500: #0ea5e9;
  --tw-cyan-600: #0284c7;
  --tw-cyan-700: #0369a1;
  --tw-cyan-800: #075985;
  --tw-cyan-900: #0c4a6e;
  --tw-cyan-950: #082f49;

  --tw-sky-50: #f0f9ff;
  --tw-sky-100: #e0f2fe;
  --tw-sky-200: #bae6fd;
  --tw-sky-300: #7dd3fc;
  --tw-sky-400: #38bdf8;
  --tw-sky-500: #0ea5e9;
  --tw-sky-600: #0284c7;
  --tw-sky-700: #0369a1;
  --tw-sky-800: #075985;
  --tw-sky-900: #0c4a6e;
  --tw-sky-950: #082f49;

  --tw-blue-50: #eff6ff;
  --tw-blue-100: #dbeafe;
  --tw-blue-200: #bfdbfe;
  --tw-blue-300: #93c5fd;
  --tw-blue-400: #60a5fa;
  --tw-blue-500: #3b82f6;
  --tw-blue-600: #2563eb;
  --tw-blue-700: #1d4ed8;
  --tw-blue-800: #1e40af;
  --tw-blue-900: #1e3a8a;
  --tw-blue-950: #172554;

  --tw-indigo-50: #eef2ff;
  --tw-indigo-100: #e0e7ff;
  --tw-indigo-200: #c7d2fe;
  --tw-indigo-300: #a5b4fc;
  --tw-indigo-400: #818cf8;
  --tw-indigo-500: #6366f1;
  --tw-indigo-600: #4f46e5;
  --tw-indigo-700: #4338ca;
  --tw-indigo-800: #3730a3;
  --tw-indigo-900: #312e81;
  --tw-indigo-950: #1e1b4b;

  --tw-violet-50: #f5f3ff;
  --tw-violet-100: #ede9fe;
  --tw-violet-200: #ddd6fe;
  --tw-violet-300: #c4b5fd;
  --tw-violet-400: #a78bfa;
  --tw-violet-500: #8b5cf6;
  --tw-violet-600: #7c3aed;
  --tw-violet-700: #6d28d9;
  --tw-violet-800: #5b21b6;
  --tw-violet-900: #4c1d95;
  --tw-violet-950: #2e1065;

  --tw-purple-50: #faf5ff;
  --tw-purple-100: #f3e8ff;
  --tw-purple-200: #e9d5ff;
  --tw-purple-300: #d8b4fe;
  --tw-purple-400: #c084fc;
  --tw-purple-500: #a855f7;
  --tw-purple-600: #9333ea;
  --tw-purple-700: #7e22ce;
  --tw-purple-800: #6b21a8;
  --tw-purple-900: #581c87;
  --tw-purple-950: #3b0764;

  --tw-fuchsia-50: #fdf4ff;
  --tw-fuchsia-100: #fae8ff;
  --tw-fuchsia-200: #f5d0fe;
  --tw-fuchsia-300: #f0abfc;
  --tw-fuchsia-400: #e879f9;
  --tw-fuchsia-500: #d946ef;
  --tw-fuchsia-600: #c026d3;
  --tw-fuchsia-700: #a21caf;
  --tw-fuchsia-800: #86198f;
  --tw-fuchsia-900: #701a75;
  --tw-fuchsia-950: #4a044e;

  --tw-pink-50: #fdf2f8;
  --tw-pink-100: #fce7f3;
  --tw-pink-200: #fbcfe8;
  --tw-pink-300: #f9a8d4;
  --tw-pink-400: #f472b6;
  --tw-pink-500: #ec4899;
  --tw-pink-600: #db2777;
  --tw-pink-700: #be185d;
  --tw-pink-800: #9d174d;
  --tw-pink-900: #831843;
  --tw-pink-950: #500724;

  --tw-rose-50: #fff1f2;
  --tw-rose-100: #ffe4e6;
  --tw-rose-200: #fecdd3;
  --tw-rose-300: #fda4af;
  --tw-rose-400: #fb7185;
  --tw-rose-500: #f43f5e;
  --tw-rose-600: #e11d48;
  --tw-rose-700: #be123c;
  --tw-rose-800: #9f1239;
  --tw-rose-900: #881337;
  --tw-rose-950: #4c0519;

}

// Slate
.text-slate-50 { color: #f8fafc !important;}
.text-slate-100 { color: #f1f5f9 !important;}
.text-slate-200 { color: #e2e8f0 !important;}
.text-slate-300 { color: #cbd5e1 !important;}
.text-slate-400 { color: #94a3b8 !important;}
.text-slate-500 { color: #64748b !important;}
.text-slate-600 { color: #475569 !important;}
.text-slate-700 { color: #334155 !important;}
.text-slate-800 { color: #1e293b !important;}
.text-slate-900 { color: #0f172a !important;}
.text-slate-950 { color: #020617 !important;}

// Gray
.text-gray-50 { color: #f9fafb !important;}
.text-gray-100 { color: #f3f4f6 !important;}
.text-gray-200 { color: #e5e7eb !important;}
.text-gray-300 { color: #d1d5db !important;}
.text-gray-400 { color: #9ca3af !important;}
.text-gray-500 { color: #6b7280 !important;}
.text-gray-600 { color: #4b5563 !important;}
.text-gray-700 { color: #374151 !important;}
.text-gray-800 { color: #1f2937 !important;}
.text-gray-900 { color: #111827 !important;}
.text-gray-950 { color: #030712 !important;}

// Zinc
.text-zinc-50 { color: #fafafa !important;}
.text-zinc-100 { color: #f4f4f5 !important;}
.text-zinc-200 { color: #e4e4e7 !important;}
.text-zinc-300 { color: #d4d4d8 !important;}
.text-zinc-400 { color: #a1a1aa !important;}
.text-zinc-500 { color: #71717a !important;}
.text-zinc-600 { color: #52525b !important;}
.text-zinc-700 { color: #3f3f46 !important;}
.text-zinc-800 { color: #27272a !important;}
.text-zinc-900 { color: #18181b !important;}
.text-zinc-950 { color: #09090b !important;}

// Neutral
.text-neutral-50 { color: #fafafa !important;}
.text-neutral-100 { color: #f5f5f5 !important;}
.text-neutral-200 { color: #e5e5e5 !important;}
.text-neutral-300 { color: #d4d4d4 !important;}
.text-neutral-400 { color: #a3a3a3 !important;}
.text-neutral-500 { color: #737373 !important;}
.text-neutral-600 { color: #525252 !important;}
.text-neutral-700 { color: #404040 !important;}
.text-neutral-800 { color: #262626 !important;}
.text-neutral-900 { color: #171717 !important;}
.text-neutral-950 { color: #0a0a0a !important;}

// Stone
.text-stone-50 { color: #fafaf9 !important;}
.text-stone-100 { color: #f5f5f4 !important;}
.text-stone-200 { color: #e7e5e4 !important;}
.text-stone-300 { color: #d6d3d1 !important;}
.text-stone-400 { color: #a8a29e !important;}
.text-stone-500 { color: #78716c !important;}
.text-stone-600 { color: #57534e !important;}
.text-stone-700 { color: #44403c !important;}
.text-stone-800 { color: #292524 !important;}
.text-stone-900 { color: #1c1917 !important;}
.text-stone-950 { color: #0c0a09 !important;}

// Red
.text-red-50 { color: #fef2f2 !important;}
.text-red-100 { color: #fee2e2 !important;}
.text-red-200 { color: #fecaca !important;}
.text-red-300 { color: #fca5a5 !important;}
.text-red-400 { color: #f87171 !important;}
.text-red-500 { color: #ef4444 !important;}
.text-red-600 { color: #dc2626 !important;}
.text-red-700 { color: #b91c1c !important;}
.text-red-800 { color: #991b1b !important;}
.text-red-900 { color: #7f1d1d !important;}
.text-red-950 { color: #450a0a !important;}

// Orange
.text-orange-50 { color: #fff7ed !important;}
.text-orange-100 { color: #ffedd5 !important;}
.text-orange-200 { color: #fed7aa !important;}
.text-orange-300 { color: #fdba74 !important;}
.text-orange-400 { color: #fb923c !important;}
.text-orange-500 { color: #f97316 !important;}
.text-orange-600 { color: #ea580c !important;}
.text-orange-700 { color: #c2410c !important;}
.text-orange-800 { color: #9a3412 !important;}
.text-orange-900 { color: #7c2d12 !important;}
.text-orange-950 { color: #431407 !important;}

// Amber
.text-amber-50 { color: #fffbeb !important;}
.text-amber-100 { color: #fef3c7 !important;}
.text-amber-200 { color: #fde68a !important;}
.text-amber-300 { color: #fcd34d !important;}
.text-amber-400 { color: #fbbf24 !important;}
.text-amber-500 { color: #f59e0b !important;}
.text-amber-600 { color: #d97706 !important;}
.text-amber-700 { color: #b45309 !important;}
.text-amber-800 { color: #92400e !important;}
.text-amber-900 { color: #78350f !important;}
.text-amber-950 { color: #451a03 !important;}

// Yellow
.text-yellow-50 { color: #fefce8 !important;}
.text-yellow-100 { color: #fef9c3 !important;}
.text-yellow-200 { color: #fef08a !important;}
.text-yellow-300 { color: #fde047 !important;}
.text-yellow-400 { color: #facc15 !important;}
.text-yellow-500 { color: #eab308 !important;}
.text-yellow-600 { color: #ca8a04 !important;}
.text-yellow-700 { color: #a16207 !important;}
.text-yellow-800 { color: #854d0e !important;}
.text-yellow-900 { color: #713f12 !important;}
.text-yellow-950 { color: #422006 !important;}

// Lime
.text-lime-50 { color: #f7fee7 !important;}
.text-lime-100 { color: #ecfccb !important;}
.text-lime-200 { color: #d9f99d !important;}
.text-lime-300 { color: #bef264 !important;}
.text-lime-400 { color: #a3e635 !important;}
.text-lime-500 { color: #84cc16 !important;}
.text-lime-600 { color: #65a30d !important;}
.text-lime-700 { color: #4d7c0f !important;}
.text-lime-800 { color: #3f6212 !important;}
.text-lime-900 { color: #365314 !important;}
.text-lime-950 { color: #1a2e05 !important;}

// Green
.text-green-50 { color: #f0fdf4 !important;}
.text-green-100 { color: #dcfce7 !important;}
.text-green-200 { color: #bbf7d0 !important;}
.text-green-300 { color: #86efac !important;}
.text-green-400 { color: #4ade80 !important;}
.text-green-500 { color: #22c55e !important;}
.text-green-600 { color: #16a34a !important;}
.text-green-700 { color: #15803d !important;}
.text-green-800 { color: #166534 !important;}
.text-green-900 { color: #14532d !important;}
.text-green-950 { color: #052e16 !important;}

// Emerald
.text-emerald-50 { color: #ecfdf5 !important;}
.text-emerald-100 { color: #d1fae5 !important;}
.text-emerald-200 { color: #a7f3d0 !important;}
.text-emerald-300 { color: #6ee7b7 !important;}
.text-emerald-400 { color: #34d399 !important;}
.text-emerald-500 { color: #10b981 !important;}
.text-emerald-600 { color: #059669 !important;}
.text-emerald-700 { color: #047857 !important;}
.text-emerald-800 { color: #065f46 !important;}
.text-emerald-900 { color: #064e3b !important;}
.text-emerald-950 { color: #022c22 !important;}

// Teal
.text-teal-50 { color: #f0fdfa !important;}
.text-teal-100 { color: #ccfbf1 !important;}
.text-teal-200 { color: #99f6e4 !important;}
.text-teal-300 { color: #5eead4 !important;}
.text-teal-400 { color: #2dd4bf !important;}
.text-teal-500 { color: #14b8a6 !important;}
.text-teal-600 { color: #0d9488 !important;}
.text-teal-700 { color: #0f766e !important;}
.text-teal-800 { color: #115e59 !important;}
.text-teal-900 { color: #134e4a !important;}
.text-teal-950 { color: #042f2e !important;}

// Cyan
.text-cyan-50 { color: #f0f9ff !important;}
.text-cyan-100 { color: #e0f2fe !important;}
.text-cyan-200 { color: #bae6fd !important;}
.text-cyan-300 { color: #7dd3fc !important;}
.text-cyan-400 { color: #38bdf8 !important;}
.text-cyan-500 { color: #0ea5e9 !important;}
.text-cyan-600 { color: #0284c7 !important;}
.text-cyan-700 { color: #0369a1 !important;}
.text-cyan-800 { color: #075985 !important;}
.text-cyan-900 { color: #0c4a6e !important;}
.text-cyan-950 { color: #082f49 !important;}

// Sky
.text-sky-50 { color: #f0f9ff !important;}
.text-sky-100 { color: #e0f2fe !important;}
.text-sky-200 { color: #bae6fd !important;}
.text-sky-300 { color: #7dd3fc !important;}
.text-sky-400 { color: #38bdf8 !important;}
.text-sky-500 { color: #0ea5e9 !important;}
.text-sky-600 { color: #0284c7 !important;}
.text-sky-700 { color: #0369a1 !important;}
.text-sky-800 { color: #075985 !important;}
.text-sky-900 { color: #0c4a6e !important;}
.text-sky-950 { color: #082f49 !important;}

// Blue
.text-blue-50 { color: #eff6ff !important;}
.text-blue-100 { color: #dbeafe !important;}
.text-blue-200 { color: #bfdbfe !important;}
.text-blue-300 { color: #93c5fd !important;}
.text-blue-400 { color: #60a5fa !important;}
.text-blue-500 { color: #3b82f6 !important;}
.text-blue-600 { color: #2563eb !important;}
.text-blue-700 { color: #1d4ed8 !important;}
.text-blue-800 { color: #1e40af !important;}
.text-blue-900 { color: #1e3a8a !important;}
.text-blue-950 { color: #172554 !important;}

// Indigo
.text-indigo-50 { color: #eef2ff !important;}
.text-indigo-100 { color: #e0e7ff !important;}
.text-indigo-200 { color: #c7d2fe !important;}
.text-indigo-300 { color: #a5b4fc !important;}
.text-indigo-400 { color: #818cf8 !important;}
.text-indigo-500 { color: #6366f1 !important;}
.text-indigo-600 { color: #4f46e5 !important;}
.text-indigo-700 { color: #4338ca !important;}
.text-indigo-800 { color: #3730a3 !important;}
.text-indigo-900 { color: #312e81 !important;}
.text-indigo-950 { color: #1e1b4b !important;}

// Violet
.text-violet-50 { color: #f5f3ff !important;}
.text-violet-100 { color: #ede9fe !important;}
.text-violet-200 { color: #ddd6fe !important;}
.text-violet-300 { color: #c4b5fd !important;}
.text-violet-400 { color: #a78bfa !important;}
.text-violet-500 { color: #8b5cf6 !important;}
.text-violet-600 { color: #7c3aed !important;}
.text-violet-700 { color: #6d28d9 !important;}
.text-violet-800 { color: #5b21b6 !important;}
.text-violet-900 { color: #4c1d95 !important;}
.text-violet-950 { color: #2e1065 !important;}

// Purple
.text-purple-50 { color: #faf5ff !important;}
.text-purple-100 { color: #f3e8ff !important;}
.text-purple-200 { color: #e9d5ff !important;}
.text-purple-300 { color: #d8b4fe !important;}
.text-purple-400 { color: #c084fc !important;}
.text-purple-500 { color: #a855f7 !important;}
.text-purple-600 { color: #9333ea !important;}
.text-purple-700 { color: #7e22ce !important;}
.text-purple-800 { color: #6b21a8 !important;}
.text-purple-900 { color: #581c87 !important;}
.text-purple-950 { color: #3b0764 !important;}

// Fuchsia
.text-fuchsia-50 { color: #fdf4ff !important;}
.text-fuchsia-100 { color: #fae8ff !important;}
.text-fuchsia-200 { color: #f5d0fe !important;}
.text-fuchsia-300 { color: #f0abfc !important;}
.text-fuchsia-400 { color: #e879f9 !important;}
.text-fuchsia-500 { color: #d946ef !important;}
.text-fuchsia-600 { color: #c026d3 !important;}
.text-fuchsia-700 { color: #a21caf !important;}
.text-fuchsia-800 { color: #86198f !important;}
.text-fuchsia-900 { color: #701a75 !important;}
.text-fuchsia-950 { color: #4a044e !important;}

// Pink
.text-pink-50 { color: #fdf2f8 !important;}
.text-pink-100 { color: #fce7f3 !important;}
.text-pink-200 { color: #fbcfe8 !important;}
.text-pink-300 { color: #f9a8d4 !important;}
.text-pink-400 { color: #f472b6 !important;}
.text-pink-500 { color: #ec4899 !important;}
.text-pink-600 { color: #db2777 !important;}
.text-pink-700 { color: #be185d !important;}
.text-pink-800 { color: #9d174d !important;}
.text-pink-900 { color: #831843 !important;}
.text-pink-950 { color: #500724 !important;}

// Rose
.text-rose-50 { color: #fff1f2 !important;}
.text-rose-100 { color: #ffe4e6 !important;}
.text-rose-200 { color: #fecdd3 !important;}
.text-rose-300 { color: #fda4af !important;}
.text-rose-400 { color: #fb7185 !important;}
.text-rose-500 { color: #f43f5e !important;}
.text-rose-600 { color: #e11d48 !important;}
.text-rose-700 { color: #be123c !important;}
.text-rose-800 { color: #9f1239 !important;}
.text-rose-900 { color: #881337 !important;}
.text-rose-950 { color: #4c0519 !important;}