header {
  display: block;
  position: relative;
  background-color: white;
  border-bottom: 1px solid #1e325c;
  padding: 1rem 2.5rem;
  color: #1e325c;
  @media screen and (min-width: 1200px) {
    padding: 1.5rem 2.5rem;
  }
  h4 {
    color: hsl(212deg, 100%, 50%);
  }
  .heading {
    color: hsl(212deg, 100%, 50%);
    font-size: 1.15rem;
    font-weight: 500;
  }
}

.header-subnav {
  position: relative;
  padding: 0.75rem 2.5rem;
  background-color: #1e325c;
  color: white;
  ul.subnav {
    font-size: 0.85rem;
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      flex-direction: row;
    }
    li.subnav-item {
      color: white;
      @media (min-width: 992px) {
      }
      .subnav-link {
        color: white;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-right: 1.5rem;
      }
      &.nav-item-active {
        font-weight: 600;
        color: white;
        .subnav-link {
          color: #f2e500;
        }
      }
      .link-icon-wrapper {
        position: relative;
        display: block;
        width: 24px;
        height: 24px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-bottom: 0.15rem;
        overflow: hidden !important;
        .link-icon {
          position: relative;
          display: block;
          top: 0;
          left: 0;
          width: 24px;
          height: 24px;
          transform: translate3d(0, 0, 0);
          transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
          &::before {
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            transform: translate3d(0, -100%, 0);
            transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
          }
          &.link-icon-home {
            background-image: url("/img/sidebar/home-white.svg");
          }
          &.link-icon-home-plain {
            background-image: url("/img/sidebar/home-plain-white.svg");
          }
          &.link-icon-atom {
            background-image: url("/img/sidebar/atom-white.svg");
          }
          &.link-icon-atom2 {
            background-image: url("/img/sidebar/atom2-white.svg");
          }
          &.link-icon-bike {
            background-image: url("/img/sidebar/bike-white.svg");
          }
          &.link-icon-book {
            background-image: url("/img/sidebar/book-white.svg");
          }
          &.link-icon-document {
            background-image: url("/img/sidebar/document-white.svg");
          }
          &.link-icon-education {
            background-image: url("/img/sidebar/education-white.svg");
          }
          &.link-icon-newspaper {
            background-image: url("/img/sidebar/newspaper-white.svg");
          }
          &.link-icon-paperplane {
            background-image: url("/img/sidebar/paperplane-white.svg");
          }
          &.link-icon-laposte {
            background-image: url("/img/sidebar/laposte-white.svg");
          }
          &.link-icon-power {
            background-image: url("/img/sidebar/power-white.svg");
          }
          &.link-icon-repair {
            background-image: url("/img/sidebar/repair-white.svg");
          }
          &.link-icon-shop {
            background-image: url("/img/sidebar/shop-white.svg");
          }
          &.link-icon-talk {
            background-image: url("/img/sidebar/talk-white.svg");
          }
          &.link-icon-user {
            background-image: url("/img/sidebar/user-white.svg");
          }
          &.link-icon-video {
            background-image: url("/img/sidebar/video-white.svg");
          }
          &.link-icon-warranty {
            background-image: url("/img/sidebar/warranty-white.svg");
          }
          &.link-icon-expertise {
            background-image: url("/img/sidebar/expertise-white.svg");
          }
          &.link-icon-truck {
            background-image: url("/img/sidebar/truck-white.svg");
          }
          &.link-icon-cogandtool {
            background-image: url("/img/sidebar/cogandtool-white.svg");
          }
        }
      }
    }
  }
}

#header-mobile {
  display: flex;
  height: 80px;
  background-color: hsl(212deg, 20%, 96%);
  .c-brand-logo-mobile {
    display: block;
    background-image: url("/img/logo_darkblue.svg");
    background-size: contain;
    background-position: center left;
    background-repeat: no-repeat;
    height: 80px;
    width: calc(100% - 80px);
  }
  @media (min-width: 992px) {
    display: none;
  }
}