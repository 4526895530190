.customer-item {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  background: white;
  padding: 0.25rem 1rem;
  margin-bottom: 0.25rem;
  .info {

  }
  .assortments {
    display: flex;
    gap: 0.35rem;
    .assortment {
      padding: 0.35rem 0.5rem;
      font-size: 0.8rem;
      background: rgb(220, 220, 227);
      line-height: 1rem;
      border-radius: 0.25rem;
      &.expired {
        background-color: rgb(213, 39, 9);
        color: white;
      }
      .name {
        font-weight: 600;
      }
    }
  }
  .actions {
    width: 120px;
  }
}