.card {
  border: 0;
  border-radius: 0.5rem;
  background-color: white;
  padding: 1rem 1.5rem;
  display: grid;
  margin-bottom: 0.5rem;
  &.card-warning-light {
    background-color: hsl(47deg, 93%, 80%);
  }

  &.card-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 2rem;
    cursor: pointer;
    &:hover {
      background: hsl(212deg, 100%, 50%);
      color: white;
    }
  }
  &.card-white {
    background-color: white;
  }
  &.card-ticket {
    background-color: white;
    dl.ticket-information {
      font-size: 0.8rem;
      dt {

      }
      dd {
        margin-bottom: 0.25rem;
        @media (min-width: 992px) {
          margin-bottom: 0;
        }
      }
    }
    .meta-information {
      font-size: 0.8rem;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      @media (min-width: 1200px) {
        flex-direction: column;
        gap: 0;
      }
      .meta-item {
        text-transform: uppercase;
      }
    }
  }
  &.card-bordered {
    background-color: white;
    border-radius: 0.35rem;
    border: 1px solid hsl(220deg, 30%, 90%);
    .card-body {
      padding: 1.25rem 1.5rem;
    }
    &__hoverable {
      &:hover {
        border: 1px solid hsl(220deg, 80%, 60%);
      }
    }
  }
}
