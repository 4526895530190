.btn {
  border: 1px solid #eff4f9 !important;
  background-color: #eff4f9 !important;
  color: #003366 !important;
  padding: 0.5rem 1.15rem !important;
  font-size: 1rem;
  &:hover {
    background-color: #eaedef !important;
  }
  &.btn-primary {
    background-color: #0077ff !important;
    border: 1px solid #0077ff !important;
    color: white !important;
    &:hover {
      background-color: #006edb !important;
      border: 1px solid #006edb !important;
    }
  }
  &.btn-sm {
    font-size: 0.8rem;
    padding: 0.35rem 0.75rem;
  }
}
