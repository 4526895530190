div#fs-menu {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 24000;
  align-items: center;
  flex-direction: column;
  &.show {
    display: flex;
  }
  @media (min-width: 992px) {
    display: none !important;
  }
  .c-brand-logo {
    display: block;
    background-image: url("/img/logo_darkblue.svg");
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    height: 90px;
    width: 100%;
  }
  #main-nav {
    width: 100%;
    .c-sidebar-nav {
      .nav-item {
        width: inherit;
        list-style: none;
        display: block;
        float: left;
        width: 100%;
        .nav-link {
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: flex-start;
          text-align: center;
          padding: 0.25rem 1.5rem;
          color: #003066;
          line-height: 1rem;
          width: 100%;
          height: 100%;
          .link-icon-wrapper {
            position: relative;
            display: block;
            width: 24px;
            height: 24px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            overflow: hidden !important;
            .link-icon {
              position: relative;
              display: block;
              top: 0;
              left: 0;
              width: 24px;
              height: 24px;
              &.link-icon-home {
                background-image: url("/img/sidebar/home-darkblue.svg");
              }
              &.link-icon-home-plain {
                background-image: url("/img/sidebar/home-plain-darkblue.svg");
              }
              &.link-icon-atom {
                background-image: url("/img/sidebar/atom-darkblue.svg");
              }
              &.link-icon-atom2 {
                background-image: url("/img/sidebar/atom2-darkblue.svg");
              }
              &.link-icon-bike {
                background-image: url("/img/sidebar/bike-darkblue.svg");
              }
              &.link-icon-book {
                background-image: url("/img/sidebar/book-darkblue.svg");
              }
              &.link-icon-document {
                background-image: url("/img/sidebar/document-darkblue.svg");
              }
              &.link-icon-education {
                background-image: url("/img/sidebar/education-darkblue.svg");
              }
              &.link-icon-newspaper {
                background-image: url("/img/sidebar/newspaper-darkblue.svg");
              }
              &.link-icon-paperplane {
                background-image: url("/img/sidebar/paperplane-darkblue.svg");
              }
              &.link-icon-laposte {
                background-image: url("/img/sidebar/laposte-darkblue.svg");
              }
              &.link-icon-power {
                background-image: url("/img/sidebar/power-darkblue.svg");
              }
              &.link-icon-repair {
                background-image: url("/img/sidebar/repair-darkblue.svg");
              }
              &.link-icon-shop {
                background-image: url("/img/sidebar/shop-darkblue.svg");
              }
              &.link-icon-talk {
                background-image: url("/img/sidebar/talk-darkblue.svg");
              }
              &.link-icon-user {
                background-image: url("/img/sidebar/user-darkblue.svg");
              }
              &.link-icon-video {
                background-image: url("/img/sidebar/video-darkblue.svg");
              }
            }
          }
          &:hover {
            .link-icon-wrapper {
              .link-icon {
                display: block;
                width: 24px;
                height: 24px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                margin-bottom: 0.15rem;
              }
            }
          }
        }
        &.nav-item-active {
          border-color: hsl(212deg, 100%, 50%);
          font-weight: 700;
          color: hsl(212deg, 100%, 50%);
          .nav-link {
            color: inherit;
            .link-icon-wrapper {
              .link-icon {
                &.link-icon-home {
                  background-image: url("/img/sidebar/home-primary.svg");
                }
                &.link-icon-home-plain {
                  background-image: url("/img/sidebar/home-plain-primary.svg");
                }
                &.link-icon-atom {
                  background-image: url("/img/sidebar/atom-primary.svg");
                }
                &.link-icon-atom2 {
                  background-image: url("/img/sidebar/atom2-primary.svg");
                }
                &.link-icon-bike {
                  background-image: url("/img/sidebar/bike-primary.svg");
                }
                &.link-icon-book {
                  background-image: url("/img/sidebar/book-primary.svg");
                }
                &.link-icon-document {
                  background-image: url("/img/sidebar/document-primary.svg");
                }
                &.link-icon-education {
                  background-image: url("/img/sidebar/education-primary.svg");
                }
                &.link-icon-newspaper {
                  background-image: url("/img/sidebar/newspaper-primary.svg");
                }
                &.link-icon-paperplane {
                  background-image: url("/img/sidebar/paperplane-primary.svg");
                }
                &.link-icon-laposte {
                  background-image: url("/img/sidebar/laposte-primary.svg");
                }
                &.link-icon-power {
                  background-image: url("/img/sidebar/power-primary.svg");
                }
                &.link-icon-repair {
                  background-image: url("/img/sidebar/repair-primary.svg");
                }
                &.link-icon-shop {
                  background-image: url("/img/sidebar/shop-primary.svg");
                }
                &.link-icon-talk {
                  background-image: url("/img/sidebar/talk-primary.svg");
                }
                &.link-icon-user {
                  background-image: url("/img/sidebar/user-primary.svg");
                }
                &.link-icon-video {
                  background-image: url("/img/sidebar/video-primary.svg");
                }
              }
            }
          }
        }
      }
    }
  }
  #sub-nav {
    width: 100%;
    .c-sidebar-subnav {
      .nav-item {
        display: flex;
        height: 28px;
        .nav-link {
          padding: 0.25rem 1.5rem;
          &.nav-link-danger {
            color: hsl(349deg, 78%, 53%);
          }
        }
      }
    }
  }
}

#fs-menu-toggler {
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  background: transparent;
  cursor: pointer;
  right: 0;
  .bars {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    margin: -7px auto auto -7px;
    .bar {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #003366;
      left: 0px;
      &:before {
        display: block;
        position: absolute;
        width: 0px;
        height: 2px;
        background-color: rgb(0, 0, 0);
        content: "";
        transition: cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
      }
      &:after {
        display: block;
        position: absolute;
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #0077ff;
        content: '';
        transition: cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
      }
      &:nth-child(2) {
        top: 6px;
        &:before {
          transition-delay: 50ms;
        }
        &:after {
          transition-delay: 100ms;
        }
      }
      &:nth-child(3) {
        top: 12px;
        &:before {
          transition-delay: 150ms;
        }
        &:after {
          transition-delay: 200ms;
        }
      }
    }
  }
  &:hover {
    .bars {
      .bar {
        &:before {
          width: 100%;
        }
        &:after {
          width: 100%;
        }
      }
    }
  }
}
#fs-menu-close {
  display: flex;
  width: 100%;
  background-color: #0077ff;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 48px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: hsl(212, 100%, 45%);
  }
}
