.modal-mask {
  background: rgba(0, 0, 0, 0.25) !important;
}
.modal-container {
  border-radius: 0.5rem !important;
}

.modal-header {
  padding: unset !important;
  padding-top: 1rem !important;
  border-bottom: unset !important;
}

.modal-body {
  padding: unset !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.modal-footer {
  border-top: unset !important;
  padding: unset !important;
  padding-bottom: 1rem !important;
}

.modal-default-button {
  background-color: hsl(212deg, 100%, 50%) !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  padding: 0.75rem 1.25rem !important;
  border-radius: 0.5rem !important;
  transition-duration: 100ms !important;
  transition-delay: 0ms !important;
  border-radius: 0.45rem !important;
  &:hover {
    background-color: hsl(212deg, 100%, 40%) !important;
    color: #ffffff;
  }
}
