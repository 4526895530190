ul.pagination {
  gap: 0.15rem;
  .page-item {
    border-radius: 0 !important;
    &.active {
      background-color: hsl(220, 100%, 50%) !important;
      font-weight: 600;
      &:hover {
        .page-link {
          background-color: hsl(220, 100%, 50%) !important;
        }
      }
    }
    .page-link {
      border-radius: 0.15rem !important;
      border: 0 !important;
      box-shadow: 0px 1px 1px rgba(0,0,0,0.05), 0px 2px 6px rgba(0,0,0,0.05);
      &:hover {
        background-color: hsl(220, 100%, 95%) !important;
      }
    }
  }
}