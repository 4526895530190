h1 {
  font-weight: 600;
  font-size: 2rem;
}

h2 {
}

h3 {
  font-weight: 500;
}

h4 {
  font-weight: 600;
  margin-bottom: 0.5rem;
  a {
    color: unset !important;
    color: black;
  }
}

h5 {
  font-weight: 600;
}
