#info-bar {
  background: hsl(212, 50%, 40%);
  padding: 1rem 0;
  color: white;
}

.flash-news {
  display: inline-flex;
  flex-direction: row;
  padding: 0 1rem;
  .fn-title {
    font-family: "Cera Pro Bold" !important;
    padding: 0px 0.5rem;
    color: #46c8ff;
    text-transform: uppercase;
  }
  .fn-content {
    font-family: "Cera Pro Regular";
    padding: 0px 0.5rem;
  }
}
