.alert {
  border-radius: 0.35rem;
  background-color: rgba(0, 85, 255, 0.12);
  padding: 1.25rem 2rem;
  color: rgb(4, 11, 91);
  border-radius: 0.25rem;
  &.alert-full {
    border: 0;
    border-radius: 0;
    font-size: 1rem;
    padding: 0.75rem 2.5rem;
    &.alert-danger {
      background: rgb(198, 0, 13);
      background: rgb(245, 238, 238);
      color: rgb(198, 0, 13);
      border-left: 4px solid rgb(198, 0, 13);
    }
    &.alert-success {
      background: rgb(210, 245, 222);
      color: rgb(0, 156, 55);
      border-left: rgb(0, 156, 55);
    }
  }
}
