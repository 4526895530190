.picto {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  &.picto-shop {
    background-image: url("/img/sidebar/shop-darkblue.svg");
  }
  &.picto-valid {
    background-image: url("/img/sidebar/valid-darkblue.svg");
    &.picto-valid-primary {
      background-image: url("/img/sidebar/valid-primary.svg");
    }
    &.picto-valid-freshgreen {
      background-image: url("/img/sidebar/valid-freshgreen.svg");
    }
    &.picto-valid-white {
      background-image: url("/img/sidebar/valid-white.svg");
    }
  }
  &.picto-dot-green {
    background-image: url("/img/sidebar/dot-freshgreen.svg");
  }
  &.picto-dot-red {
    background-image: url("/img/sidebar/dot-red.svg");
  }
  &.picto-dot-default {
    background-image: url("/img/sidebar/dot-default.svg");
  }
}
