.post-content {
    ul {
        li {
            margin-left: 1rem;
            list-style: square;
            p {
                margin-bottom: 0.25rem;
            }
        }
    }
}