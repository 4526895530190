ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &.nav {
    &.nav-tabs {
      display: block;
      border-bottom: none;
      li {
        display: block;
        padding: 0.25rem 1rem;
        border-radius: 0.5rem;
        &:hover {
          background: hsl(212deg, 50%, 95%);
        }
        &.active {
          background: hsl(212deg, 100%, 50%);
          a {
            color: white;
            font-weight: 600;
          }
        }
      }
    }
    border-bottom: none;
  }
}
