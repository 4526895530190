.transport-damage-item {
  background-color: white;
  padding: 1rem 1.5rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 0.5rem;
  .inner {
    display: grid;
    grid-template-columns: 1fr auto;
    &--badges {
      width: 120px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .badge-title {
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }
  }
  .row-attribute {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    .attr-k,
    .attr-key,
    .attr-label {
      font-weight: 600;
    }
  }
}