.expertise-item {
  background-color: white;
  padding: 1rem 1.5rem;
  display: grid;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 0.5rem;
  .inner {
    display: grid;
    grid-template-columns: auto 1fr 1fr auto;
    .info {
      width: 180px;
    }
    .bike {
  
    }
    .component {
  
    }
    .status {
      width: 120px;
      .badge-title {
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }
    padding-bottom: 0.5rem;
  }
  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid rgb(237, 237, 237);
    padding-top: 0.5rem;
    .dates {
      display: flex;
      gap: 1rem;
    }
  }
  .row-attribute {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    .attr-key {
      font-weight: 600;
    }
  }
}